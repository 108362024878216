$(function() {
	var pagetop = $('[data-pagetop]');

    pagetop.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
			pagetop.fadeIn();
		} else {
			pagetop.fadeOut();
		}
	});
    	
	pagetop.click(function () {
		$('body,html')
			.animate({scrollTop: 0}, 500, 'easeOutQuart');
		return false;
	});
});

/*--------------------------------------------------------------------------*
 * アニメーション
 *--------------------------------------------------------------------------*/
$(window).on('load', function(){
    var windowHeight = $(window).height(); //ウインドウの高さを取得
    var winW = $(window).width();
    
    lineAnimation();
    textMove();

    $(window).on('scroll', function(){
        lineAnimation();
        textMove();
    });

    //スクロールに合わせてフェードイン
    function lineAnimation(){
        var scroll = $(window).scrollTop(); //スクロールの位置を取得 
        $('[data-animation]').each(function(){
            var position = $(this).offset().top;
            if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
                $(this).addClass('js-active'); //クラス「js-active」を与える
            }
        });
    }

    //スクロールに合わせてテキスト表示
    function textMove(){
        var scroll = $(window).scrollTop(); //スクロールの位置を取得 
        $('[data-textMove]').each(function(){
            var position = $(this).offset().top;
            if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
                $(this).addClass('js-move'); //クラス「js-move」を与える
            }
        });
    }
});

/*--------------------------------------------------------------------------*
 * object-fit (IE対策)
 *--------------------------------------------------------------------------*/
objectFitImages('img.object-fit-img');